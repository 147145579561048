/* eslint-disable eqeqeq */
import moment from "moment";
import {difference} from "lodash";
const defaultDatesValue = { value: '', className: '', schedules: {} };

export const getStartEndIndexFromRange = (range, addToEnd = 0) => {
    return {
        start: Math.min(range.startRow.rowIndex, range.endRow.rowIndex),
        end: Math.max(range.startRow.rowIndex, range.endRow.rowIndex) + addToEnd
    }
}
export const getRosterScheduleTypesToCreate = (newValue, currentRowDateData, date) => {
    return difference([...newValue], [...currentRowDateData.value]).map(code => ({
        rosterCandidateId: currentRowDateData.rosterCandidateId,
        rosterScheduleTypeCode: code,
        rosterCandidateScheduleId: 0,
        column: date
    }))
}
export const getRosterScheduleTypesToRemove = (newValue, currentRowDateData, date) => {
    var diff = difference([...currentRowDateData.value], [...newValue])
    var filter = diff.filter(code => currentRowDateData.schedules[code] > 0 || code == "T")
    var map = filter.map(code => ({
        rosterCandidateId: currentRowDateData.rosterCandidateId,
        rosterScheduleTypeCode: code,
        rosterCandidateScheduleId: currentRowDateData.schedules[code],
        column: date
    }));
    //console.log('getRosterScheduleTypesToRemove', { newValue, currentRowDateData, date, diff, filter, map });
    return map;
    //return difference([...currentRowDateData.value], [...newValue]).filter(code => currentRowDateData.schedules[code] > 0).map(code => ({
    //    rosterCandidateId: currentRowDateData.rosterCandidateId,
    //    rosterScheduleTypeCode: code,
    //    rosterCandidateScheduleId: currentRowDateData.schedules[code],
    //    column: date
    //}))
}
export const getPayloadForRosterScheduleUpdate = (newValue, currentRowData, date) => {
    const currentScheduleDateData = currentRowData.dates[date] || defaultDatesValue;
    const create = getRosterScheduleTypesToCreate(newValue, currentScheduleDateData, date);
    const remove = getRosterScheduleTypesToRemove(newValue, currentScheduleDateData, date);
    //console.log('getPayloadForRosterScheduleUpdate: ', { newValue, currentRowData, date, currentScheduleDateData, create, remove })
    return {
        candidateId: currentRowData.candidateId,
        rosterCandidateId: currentRowData.rosterCandidateId,
        date: moment(date).format("YYYY-MM-DD"),
        create,
        remove,
    }
}

export const excelStyles = (startDate, endDate) => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 9,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 9,
            },
        },
    ];
};

export default {
    getStartEndIndexFromRange,
    getPayloadForRosterScheduleUpdate,
    defaultDatesValue,
};