import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from "../../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, CardTitle,
   TabContent,
   TabPane
} from "reactstrap";
import RecruitmentPersonEditor from "./Editor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../../loaders";
import Grid from "../../../Grid";
import SweetAlert from 'react-bootstrap-sweetalert';
import NavbarPage from '../../../navbars/NavbarPage';

export default function List(props) {
    //const { token } = useMsal();

    const defaultSort = useMemo(() => [{ id: "name", desc: false }],
        []
    );

    const [ activeTab, setActiveTab ] = useState(0);
    const [ activeData, setActiveData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ activeFilters, setActiveFilters ] = useState([]);
    const [ activeSortBy, setActiveSortBy ] = useState(defaultSort);
    const [ activePageNumber, setActivePageNumber ] = useState(1);
    const [ activePageSize, setActivePageSize ] = useState(10);
    const [ activeTotalRecords, setActiveTotalRecords ] = useState(0);
    const [ activationAlert, setActivationAlert ] = useState([]);
    const skipPageResetActiveRef = useRef();

    const [ inactiveData, setInactiveData ] = useState([]);
    const [ inactiveFilters, setInactiveFilters ] = useState([]);
    const [ inactiveSortBy, setInactiveSortBy ] = useState(defaultSort);
    const [ inactivePageNumber, setInactivePageNumber ] = useState(1);
    const [ inactivePageSize, setInactivePageSize ] = useState(10);
    const [ inactiveTotalRecords, setInactiveTotalRecords ] = useState(0);
    const skipPageResetInactiveRef = useRef();

    const [ deleteAlert, setDeleteAlert ] = useState([]);
    const apiurl = "recruitmentperson";
    
    const loadActiveData = useCallback(() => {
        skipPageResetActiveRef.current = true;
        const queryString = qs.stringify({filters: [...activeFilters], sortBy: [...activeSortBy]}, { allowDots: true });
        setItemInEdit(null);
        setActiveData([]);
            setLoading(true);
            api.get(`/${apiurl}/active/${(activePageNumber - 1) * activePageSize}/${activePageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                setActiveData(data.data);
                setActiveTotalRecords(data.total);
            }).catch((error) => {
                console.error("error: ", error);
            }).finally(() => setLoading(false));
    }, [activeFilters, activeSortBy, activePageNumber, activePageSize]);
    
    const loadInactiveData = useCallback(() => {
        skipPageResetInactiveRef.current = true;
        const queryString = qs.stringify({filters: [...activeFilters], sortBy: [...activeSortBy]}, { allowDots: true });
        setItemInEdit(null);
        setInactiveData([]);
            setLoading(true);
            api.get(`/${apiurl}/inactive/${(activePageNumber - 1) * activePageSize}/${activePageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                setInactiveData(data.data);
                setInactiveTotalRecords(data.total);
            }).catch((error) => {
                console.error("error: ", error);
            }).finally(() => setLoading(false));
    }, [inactiveFilters, inactiveSortBy, inactivePageNumber, inactivePageSize]);
    
    const handleDeactivation = (item) => {
        const success = () => {
            setActivationAlert([
                <SweetAlert success title="Success!" onConfirm={() => setActivationAlert([])}>
                    The record has been deactivated.
                </SweetAlert>
            ]);
            if (activeData.length === 1 && activePageNumber > 1) {
                setActivePageNumber(activePageNumber - 1);
            } else {
                loadActiveData();
            }
        };
        const failure = (message) => setActivationAlert([
                <SweetAlert error title="Error!" onConfirm={() => setActivationAlert([])}>
                {
                    message ?
                    <span>{message}</span>
                    :
                    <span>An error occurred deactivating the record.</span>
                }
                </SweetAlert>
            ]);

        setActivationAlert([
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title={<span className="mb-0 h4">Are you sure?</span>}
                onConfirm={() => {
                    setLoading(true);
                    api.post(`/${apiurl}/activation/${item.recruitmentPersonId}/false`).then(success).catch(failure).finally(() => setLoading(false));
                }}
                onCancel={() => setActivationAlert([])}
                focusCancelBtn
            >
                The record will be removed from other modules and moved to inactive recruiter list.
            </SweetAlert>
        ])
    }
    
    const handleActivation = (item) => {
        const success = () => {
            setActivationAlert([
                <SweetAlert success title="Success!" onConfirm={() => setActivationAlert([])}>
                    The record has been activated.
                </SweetAlert>
            ]);
            if (activeData.length === 1 && activePageNumber > 1) {
                setActivePageNumber(activePageNumber - 1);
            } else {
                loadInactiveData();
            }
        };
        const failure = (message) => setActivationAlert([
                <SweetAlert error title="Error!" onConfirm={() => setActivationAlert([])}>
                {
                    message ?
                    <span>{message}</span>
                    :
                    <span>An error occurred activating the record.</span>
                }
                </SweetAlert>
            ]);

        setActivationAlert([
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title={<span className="mb-0 h4">Are you sure?</span>}
                onConfirm={() => {
                    setLoading(true);
                    api.post(`/${apiurl}/activation/${item.recruitmentPersonId}/true`).then(success).catch(failure).finally(() => setLoading(false));
                }}
                onCancel={() => setActivationAlert([])}
                focusCancelBtn
            >
                The record will be shown on other modules and moved to active recruiter list.
            </SweetAlert>
        ])
    }
    
    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (activeData.length === 1 && activePageNumber > 1) {
                setActivePageNumber(activePageNumber - 1);
            } else {
                if (activeTab === 0)
                {
                    loadActiveData();
                    return;
                }

                loadInactiveData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/${apiurl}/${item.recruitmentPersonId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    useEffect(() => {        
         loadActiveData();
    }, [activePageNumber, activePageSize, activeFilters, activeSortBy]);

    useEffect(() => {        
         loadInactiveData();
    }, [inactivePageNumber, inactivePageSize, inactiveFilters, inactiveSortBy]);

    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const activeColumns = useMemo(() => {
        return [
            {
                Header: "Name",
                id: "name",
                accessor: "displayName",
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Email",
                id: "email",
                accessor: "email",
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.recruitmentPersonId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`deactivate_${original.recruitmentPersonId}`}
                                title="Deactivate"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDeactivation(original)}
                            >
                                <i className="fas fa-times pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.recruitmentPersonId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const inactiveColumns = useMemo(() => {
        return [
            {
                Header: "Name",
                id: "name",
                accessor: "displayName",
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Email",
                id: "email",
                accessor: "email",
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_i_${original.recruitmentPersonId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`activate_${original.recruitmentPersonId}`}
                                title="Activate"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleActivation(original)}
                            >
                                <i className="fas fa-check pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_i_${original.recruitmentPersonId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const defaultColumn = useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps: activeGetTableProps,
        getTableBodyProps: activeGetTableBodyProps,
        headerGroups: activeHeaderGroups,
        rows: activeRows,
        prepareRow: activePrepareRow,
        state: activeState,
    } = useTable(
        {
            columns: activeColumns,
            data: activeData,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetActiveRef.current,
            autoResetExpanded: !skipPageResetActiveRef.current,
            autoResetGroupBy: !skipPageResetActiveRef.current,
            autoResetSelectedRows: !skipPageResetActiveRef.current,
            autoResetSortBy: !skipPageResetActiveRef.current,
            autoResetFilters: !skipPageResetActiveRef.current,
            autoResetRowState: !skipPageResetActiveRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    useEffect(() => {
        setActiveFilters(activeState.filters);
    }, [activeState.filters]);
    
    useEffect(() => {
        setActiveSortBy(activeState.sortBy);
    }, [activeState.sortBy]);

    const {
        getTableProps: inactiveGetTableProps,
        getTableBodyProps: inactiveGetTableBodyProps,
        headerGroups: inactiveHeaderGroups,
        rows: inactiveRows,
        prepareRow: inactivePrepareRow,
        state: inactiveState,
    } = useTable(
        {
            columns: inactiveColumns,
            data: inactiveData,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetInactiveRef.current,
            autoResetExpanded: !skipPageResetInactiveRef.current,
            autoResetGroupBy: !skipPageResetInactiveRef.current,
            autoResetSelectedRows: !skipPageResetInactiveRef.current,
            autoResetSortBy: !skipPageResetInactiveRef.current,
            autoResetFilters: !skipPageResetInactiveRef.current,
            autoResetRowState: !skipPageResetInactiveRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    useEffect(() => {
        setInactiveFilters(inactiveState.filters);
    }, [inactiveState.filters]);
    
    useEffect(() => {
        setInactiveSortBy(inactiveState.sortBy);
    }, [inactiveState.sortBy]);

    return (
        <>
            {loading && <Loader />}
            {activationAlert.length > 0 && activationAlert}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col>
                        <CardTitle>
                            <h4 className="text-center">Recruitment Team</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setItemInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <NavbarPage
                            name='recruiter'
                            onTabClick={(t) => {
                                setActiveTab(t.tabIndex);

                                if (t.tabIndex === 0)
                                {
                                    loadActiveData();
                                    return;
                                }

                                loadInactiveData();
                            }}
                            tabs={[
                                {
                                    title: "Active",
                                    tabIndex: 0,
                                },
                                {
                                    title: "Inactive",
                                    tabIndex: 1,
                                }
                            ]}
                            defaultActiveTab={0}
                        />
                        <TabContent className="mt-2" activeTab={activeTab}>
                            <TabPane tabId={0}>
                                <Grid
                                    height='calc(100vh - 460px)'
                                    activeTotalRecords={activeTotalRecords}
                                    activePageSize={activePageSize}
                                    rows={activeRows}
                                    tableProps={activeGetTableProps()}
                                    headerGroups={activeHeaderGroups}
                                    tableBodyProps={activeGetTableBodyProps()}
                                    prepareRow={activePrepareRow}
                                    onPagerChangePage={(pager) => {
                                        setActivePageNumber(pager.currentPage);
                                    }}
                                    onPagerChangePageSize={(size) => {
                                        setActivePageSize(size);
                                    }}
                                    needPaging={true}
                                />
                            </TabPane>
                            <TabPane tabId={1}>
                                <Grid
                                    height='calc(100vh - 460px)'
                                    activeTotalRecords={inactiveTotalRecords}
                                    activePageSize={inactivePageSize}
                                    rows={inactiveRows}
                                    tableProps={inactiveGetTableProps()}
                                    headerGroups={inactiveHeaderGroups}
                                    tableBodyProps={inactiveGetTableBodyProps()}
                                    prepareRow={inactivePrepareRow}
                                    onPagerChangePage={(pager) => {
                                        setInactivePageNumber(pager.currentPage);
                                    }}
                                    onPagerChangePageSize={(size) => {
                                        setInactivePageSize(size);
                                    }}
                                    needPaging={true}
                                />
                            </TabPane>
                        </TabContent>
                        {
                            !!itemInEdit && 
                            <RecruitmentPersonEditor 
                                item={itemInEdit} 
                                onClose={() => setItemInEdit(null)} 
                                onSaved={() => loadActiveData()}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}