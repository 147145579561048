/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../services/api";
import { Input, Label, FormGroup, Button, Modal, Container, Row, Col } from "reactstrap";
import { DropdownBox } from "../../dropdowns";
import Loader from "../../loaders";
import { v4 } from "uuid";
import _, { template } from 'lodash';
import {
    ListBox
} from "@progress/kendo-react-listbox";
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';
import { IndeterminateCheckbox } from '../../react-table/controls';
import PropTypes from "prop-types";

const ListBoxItem = (props) => {
    let { dataItem, selected, onClick, ...others } = props;
    return (
        <li {...others} onDoubleClick={onClick}>
            <span>{dataItem.name}: {dataItem.value}</span>
        </li>
    );
};

export default function SMSEditor(props) {
    const {
        onCustomSave,
        defaultMessage,
        sourcePage,
        refId,
        project,
        projectId,
        buttonSendMessage = true,
        sourceObject = {},
        onSaved,
        defaultShowProjectAttribute,
        includeSystemOnly = false,
        showMessageTemplate = null,
        showTalentReponseCheckbox = null
    } = props;

    const [itemInEdit, setItemInEdit] = React.useState([]);
    const [selectedMessage, setSelectedMessage] = React.useState({ lookupId: defaultMessage?.selectedMessage, lookupType: defaultMessage?.title, lookupValue: defaultMessage?.message });
    const [loading, setLoading] = React.useState(false);
    const [failedMessages, setFailedMessages] = useState(null);
    // const [ templateFirstLoad, setTemplateFirstLoad ] = useState(defaultMessage ? true : false);
    const [attributes, setAttributes] = React.useState([]);
    const [cursorPosition, setCursorPosition] = React.useState(0);
    const [refData, setRefData] = useState(null);
    const [validationAlert, setValidationAlert] = useState(null);
    const [needTalentResponse, setNeedTalentResponse] = useState(false);
    const [needYesNoResponse, setNeedYesNoResponse] = useState(false);
    const [needCommentResponse, setNeedCommentResponse] = useState(null);
    const [selectedMessageDetail, setSelectedMessageDetail] = useState(null);
    const [showTemplateOptions, setShowTemplateOptions] = useState(false);
    const [editorEnabled, setEditorEnabled] = useState(false);
    const [showTalentResponseOption, setShowTalentResponseOption] = useState(false);
    const [showProjectAttributes, setShowProjectAttributes] = useState(defaultShowProjectAttribute);
    const [messageMasterList, setMessageMasterList] = useState([]);
    const [inputPhoneNumberManually, setInputPhoneNumberManually] = useState(false);
    const [manualPhoneNumber, setManualPhoneNumber] = useState(null);

    // useEffect(() => {
    //     console.log('messageMasterList', messageMasterList);
    // }, [messageMasterList]);
    // useEffect(() => {
    //     console.log('SMSEditor', 'itemInEdit', itemInEdit);
    // }, [itemInEdit]);
    // useEffect(() => {
    //     console.log('SMSEditor', 'props', props);
    // }, [props]);

    // useEffect(() => {
    //     console.log('SMSEditor', 'selectedMessage', selectedMessage);
    // }, [selectedMessage]);

    // useEffect(() => {
    //     console.log('SMSEditor', 'defaultMessage', defaultMessage);
    // }, [defaultMessage]);


    //useEffect(() => {
    //    console.log('props?.lookups?.messagesMaster', props?.lookups?.messagesMaster);
    //}, [props?.lookups?.messagesMaster]);
    //useEffect(() => {
    //    console.log('props', props);
    //}, [props]);

    React.useEffect(() => {
        // console.log('props', props);
        // console.log('refId', refId);
        // console.log('candidate id: ', props.candidateId, props.items);
        if (props.candidateId > 0) {
            setLoading(true);
            const apiCalls = [
                api.get(`candidate/${props.candidateId}`), // 0
                api.get(`messagesmaster/messageattributes`), // 1
                api.get(`projectAttribute/search`), // 2
                api.get(`rosterAttribute/search`), // 3
                //api.get(`messagesmaster/list-for-user`), // 4
            ];

            Promise.all(apiCalls)
                .then((responses) => {
                    // console.log('responses', responses);
                    var candidateData = { ...responses[0].data };
                    var messageAttributeData = [...responses[1].data];
                    var projectAttributeData = [...responses[2].data];
                    var rosterAttributeData = [...responses[3].data];
                    //var messageMasterList = [...responses[4].data];

                    const {
                        candidateNotes,
                        ...rest
                    } = candidateData;

                    setItemInEdit([{
                        ...rest,
                        candidateNotes: candidateNotes && candidateNotes.length > 0
                            ? candidateNotes.map((item) => ({ ...item, uid: v4() }))
                            : []
                    }]);

                    setAttributes([
                        ...messageAttributeData.map(d => ({
                            ...d,
                            hidden: (!sourcePage && d.category !== "Candidate Message Attribute") || d.name === "{RosterCandidateSchedule.StartDate}" || d.name === "{RosterCandidateSchedule.EndDate}"
                        })),
                        ...projectAttributeData.map(m => ({
                            name: `{${m.projectAttributeName}}`,
                            category: "Project Attribute",
                            hidden: !sourcePage
                        })),
                        ...rosterAttributeData.map(m => ({
                            name: `{${m.rosterAttributeName}}`,
                            category: "Roster Attribute",
                            hidden: !sourcePage
                        }))
                    ]);
                    //setMessageMasterList(messageMasterList);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
            return;
        }

        if (sourcePage || props.items?.length) {
            setLoading(true);
            setItemInEdit(props.items.map(r => {
                // console.log(r);
                if (r.candidate)
                    return { ...r, ...r.candidate };

                return { ...r };
            }));

            const apiCalls = [
                api.get(`messagesmaster/messageattributes`),
            ];

            if (refId) {
                //const refId = props.items.candidates[0].refId;
                apiCalls.push(api.get(`projectattribute/candidate/${projectId}/${refId}`));
                apiCalls.push(api.get(`rosterattribute/roster/${refId}`));

                if (sourcePage === "roster")
                    apiCalls.push(api.get(`rostercandidate/id/${refId}`));

                if (sourcePage === "jobOrder")
                    apiCalls.push(api.get(`jobordercandidate/${refId}`));
            }
            else {
                apiCalls.push(api.get(`projectAttribute/search`));
                apiCalls.push(api.get(`rosterAttribute/search`));
            }

            Promise.all(apiCalls)
                .then((responses) => {
                    // console.log('using this 2')
                    // console.log('responses 2', responses);

                    if (!refId) {
                        // console.log({responses});
                        setAttributes([
                            ...responses[0]?.data.map(d => ({
                                ...d,
                                hidden: (onCustomSave ? false : true) && ((!sourcePage && d.category !== "Candidate Message Attribute") || d.name === "{RosterCandidateSchedule.StartDate}" || d.name === "{RosterCandidateSchedule.EndDate}")
                            })),
                            ...responses[1]?.data?.map(m => ({
                                name: `{${m.projectAttributeName}}`,
                                category: "Project Attribute",
                                hidden: !sourcePage
                            })),
                            ...responses[2]?.data.map(m => ({
                                name: `{${m.rosterAttributeName}}`,
                                category: "Roster Attribute",
                                hidden: !sourcePage
                            }))
                        ]);
                        return;
                    }

                    // console.log("roster candidate", responses[0].data, responses[3].data);
                    setRefData(responses[3].data);
                    setAttributes([
                        ...responses[0].data.map(d => ({
                            ...d,
                            hidden: (!sourcePage && d.category !== "Candidate Message Attribute") || d.name === "{RosterCandidateSchedule.StartDate}" || d.name === "{RosterCandidateSchedule.EndDate}"
                        })),
                        ...responses[1].data.map(m => ({
                            name: `{${m.name}}`,
                            value: m.value,
                            category: "Project Attribute",
                            hidden: !sourcePage
                        })),
                        ...responses[2].data.map(m => ({
                            name: `{${m.name}}`,
                            value: m.value,
                            category: "Roster Attribute",
                            hidden: !sourcePage
                    }))
                ]);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        }
    }, [props.candidateId, props.items, sourcePage]);

    // On Load
    useEffect(() => {
        // If Include System Only is true, then we need to get only system messages
        if (!includeSystemOnly) {
            const apiCalls = [
                api.get(`messagesmaster/list-for-user`), // 0
            ];


            Promise.all(apiCalls)
                .then((responses) => {
                    var messageMasterList2 = [...responses[0].data];
                    setMessageMasterList(messageMasterList2);
                })
                .catch((error) => console.error(error))
                .finally(() => { });
        }
        // If Include System Only is false, then we need to get all messages
        else {
            var newList = [...props?.lookups?.messagesMaster];
            setMessageMasterList(newList);
        }

    }, []);

    useEffect(() => {
        const messageType = selectedMessage?.lookupType?.toLowerCase() ?? "";
        setShowTemplateOptions(showMessageTemplate != false && sourcePage && messageType !== "availability" && messageType !== "accommodation" && messageType !== "das" && messageType !== "estimate availability" && messageType !== "applicant pack sent");
        setEditorEnabled(messageType !== "availability" && messageType !== "accommodation" && messageType !== "das" && messageType !== "estimate availability");
        setShowTalentResponseOption(showTalentReponseCheckbox != false && messageType !== "availability" && messageType !== "accommodation" && messageType !== "das" && messageType !== "estimate availability" && messageType !== "applicant pack sent");
        setShowProjectAttributes(defaultShowProjectAttribute && messageType !== "availability" && messageType !== "accommodation" && messageType !== "das" && messageType !== "estimate availability");
    }, [selectedMessage?.lookupType]);

    // useEffect(() => {
    //     console.log(attributes);
    // }, [attributes]);

    const messageValidation = useCallback((title, message) => {
        // console.log({message, needTalentResponse});
        if (!message || message.trim() === "") {
            setFailedMessages([{
                failCategory: "Empty Message",
            }]);
            return false;
        }

        if (!title && !showTalentResponseOption && needTalentResponse) {
            setFailedMessages([{
                failCategory: "Invalid Attribute Usage",
                message: "Attribute {FlowUrls.TalentResponseUrl} cannot be used if the message template is Availability/Accommodation/DAS."
            }]);
            return false;
        }

        if (showTalentResponseOption && message.includes("{FlowUrls.TalentResponseUrl}") && !needTalentResponse) {
            setFailedMessages([{
                failCategory: "URL Response",
            }]);
            return false;
        }

        if (showTalentResponseOption && !message.includes("{FlowUrls.TalentResponseUrl}") && needTalentResponse) {
            setFailedMessages([{
                failCategory: "Talent Response Parameter",
            }]);
            return false;
        }

        setFailedMessages(null);
        return true;
    }, [needTalentResponse, showTalentResponseOption]);

    useEffect(() => {
        const newMessageAttributes = [...attributes.map(d => ({ ...d, hidden: d.hidden || (selectedMessage?.lookupType?.toLowerCase() === "availability" || selectedMessage?.lookupType?.toLowerCase() === "accommodation") && (d.category === "Message Attribute" || d.category === "Candidate Message Attribute") && d.name === "{FlowUrls.TalentResponseUrl}" }))];
        setAttributes(newMessageAttributes);

        if (!selectedMessage?.lookupId) {
            if (selectedMessageDetail?.messageMasterId)
                setSelectedMessageDetail({ ...selectedMessage, includeUrl: false, showYesNo: false, showComment: false });
            return;
        }

        if (selectedMessage.lookupId === selectedMessageDetail?.messagesMasterId)
            return;

        setLoading(true);
        api.get(`messagesmaster/${selectedMessage.lookupId}`)
            .then((response) => {
                setSelectedMessageDetail(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [selectedMessage?.lookupId]);

    useEffect(() => {
        // console.log('message1', {templateFirstLoad, itemInEdit, selectedMessage});
        // console.log('message1', {itemInEdit, selectedMessage, selectedMessageDetail, project});

        // console.log('itemInEdit', itemInEdit);
        // console.log('selectedMessage', selectedMessage);

        if (!itemInEdit || itemInEdit.length === 0 || !selectedMessage?.lookupValue)
            return;

        setNeedTalentResponse(selectedMessageDetail?.includeUrl ?? needTalentResponse);
        setNeedYesNoResponse(selectedMessageDetail?.showYesNo ?? needYesNoResponse);
        setNeedCommentResponse(selectedMessageDetail?.showComment ?? needCommentResponse);

        let candidate = {};

        if (itemInEdit.length > 1) {
            const distinctDispatchUnit = [...new Set(itemInEdit.map(c => c.dispatchUnitId))].map(c => itemInEdit.filter(i => i.dispatchUnitId === c)[0]);
            const distinctRoster = [...new Set(itemInEdit.map(c => c.rosterId))].map(c => itemInEdit.filter(i => i.rosterId === c)[0].rosterName);
            const distinctLocation = [...new Set(itemInEdit.map(c => c.locationId))].map(c => itemInEdit.filter(i => i.locationId === c)[0].locationName);
            const distinctStartDate = [...new Set(itemInEdit.map(c => c.startDate))];
            const distinctEndDate = [...new Set(itemInEdit.map(c => c.endDate))];

            // console.log('message2', {itemInEdit, distinctDispatchUnit, distinctRoster, distinctLocation, distinctStartDate, distinctEndDate});

            candidate.projectName = project?.lookupValue ? project.lookupValue : project?.projectName;

            if (distinctDispatchUnit?.length === 1) {
                candidate.dispatchUnitName = distinctDispatchUnit[0].dispatchUnitName;
                candidate.quoteNumber = distinctDispatchUnit[0].quoteNumber;
                candidate.dispatchStartDate = distinctDispatchUnit[0].dispatchStartDate;
                candidate.dispatchEndDate = distinctDispatchUnit[0].dispatchEndDate;
            }

            if (distinctRoster?.length === 1)
                candidate.rosterName = distinctRoster[0];

            if (distinctLocation?.length === 1)
                candidate.locationName = distinctLocation[0];

            if (distinctStartDate?.length === 1)
                candidate.rosterCandidateScheduleStartDate = distinctStartDate[0];

            if (distinctEndDate?.length === 1)
                candidate.rosterCandidateScheduleEndDate = distinctEndDate[0];
        }
        else {
            // console.log('message2', {itemInEdit});
            if (refData) {
                if (sourcePage === "roster") {
                    // console.log(refData);
                    candidate = { ...refData.candidate };
                    candidate.projectName = project.lookupValue ? project.lookupValue : project.projectName;
                    candidate.dispatchUnitName = refData.roster.dispatchUnit.dispatchUnitName;
                    candidate.quoteNumber = refData.roster.dispatchUnit.quoteNumber;
                    candidate.rosterName = refData.roster.rosterName;
                    candidate.locationName = refData.roster.dispatchUnit.location.lookupValue;
                    candidate.dispatchStartDate = refData.roster.dispatchUnit.startDate;
                    candidate.dispatchEndDate = refData.roster.dispatchUnit.endDate;
                    candidate.startDate = refData.roster.dispatchUnit.startDate;
                    candidate.endDate = refData.roster.dispatchUnit.endDate;
                }

                if (sourcePage === "jobOrder") {
                    candidate = { ...refData.candidate };
                    candidate.projectName = refData.projectName;
                }
            }
            else
                candidate = { ...itemInEdit[0], rosterCandidateScheduleStartDate: itemInEdit[0].startDate, rosterCandidateScheduleEndDate: itemInEdit[0].endDate };
        }


        // If candidate is null and source object contains candidate item
        // Then use it
        if (!candidate && sourceObject && 'candidate' in sourceObject) {
            candidate = { ...sourceObject.candidate };
        }

        // console.log('message3', {itemInEdit, candidate, attributes, selectedMessage});

        let message = selectedMessage?.lookupValue;

        if (!message || message === "" || !candidate) {
            setSelectedMessage({ ...selectedMessage, lookupValue: message });
            return;
        }

        // console.log('message4', {message});

        // if (itemInEdit.length === 1)
        //     message = selectedMessage?.lookupValue?.replaceAll("{FirstName}", candidate.firstName).replaceAll("{Surname}", candidate.surname);

        // if (candidate.dispatchUnitName)
        //     message = message.replaceAll("{DispatchName}", candidate.dispatchUnitName);

        // if (candidate.rosterName)
        //     message = message.replaceAll("{RosterName}", candidate.rosterName);

        // if (candidate.locationName)
        //     message = message.replaceAll("{LocationName}", candidate.locationName);

        // if (candidate.startDate)
        //     message = message.replaceAll("{StartDate}", candidate.startDate);

        // if (candidate.endDate)
        //     message = message.replaceAll("{EndDate}", candidate.endDate);

        // console.log('candidate', candidate);
        // console.log('attributes', attributes);


        if (attributes && attributes.length > 0) {
            for (let i = 0; i < attributes.length; i++) {
                // console.log('attributes', {attributes, message});
                const attribute = attributes[i];

                if (attributes[i].category === "Message Attribute" || attributes[i].category === "Candidate Message Attribute") {
                    const valueSource = _.camelCase(attribute.valueSource);
                    // console.log("message attr", {attribute, valueSource, value: candidate[valueSource]});

                    if (attribute.replaceWhenSingle && itemInEdit.length === 1 && candidate[valueSource]) {
                        message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);
                        continue;
                    }

                    if (candidate[valueSource]) {
                        // console.log("message attr 2", {message, attribute, name: attribute.name, valueSource, value: candidate[valueSource], result: message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource])});
                        message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);
                        continue;
                    }

                    if (sourceObject && sourceObject[valueSource]) {
                        // console.log("message attr 2", {message, attribute, name: attribute.name, valueSource, value: candidate[valueSource], result: message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource])});
                        message = message.replace(new RegExp(attribute.name, 'g'), sourceObject[valueSource]);
                        continue;
                    }

                    continue;
                }

                if (attribute.value)
                    message = message.replace(new RegExp(`${attribute.name}`, 'g'), attribute.value);
            }
        }

        // console.log('message5', {message});

        setSelectedMessage({ ...selectedMessage, lookupValue: message });
        // setTemplateFirstLoad(false);
    }, [defaultMessage, itemInEdit, selectedMessage?.lookupValue, selectedMessageDetail, attributes]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleDragStart = (e) => {
        // console.log('handleDragStart', e)
        e.nativeEvent.dataTransfer.setData('text', e.dataItem.name);
        e.nativeEvent.effectAllowed = "copy";
    };

    const handleSave = useCallback((item) => {
        if (inputPhoneNumberManually && !manualPhoneNumber)
            return;

        if (!messageValidation(selectedMessage?.lookupType, selectedMessage?.lookupValue))
            return;

        var sourcePage_ = sourcePage == null || sourcePage == undefined ? null : sourcePage.trim().toLowerCase();
        // console.log('handleSave');
        // console.log('item', item);
        // console.log('sourcePage_', sourcePage_);

        var messageLog = {
            messagesMasterId: selectedMessage?.lookupId,
            refIds: sourcePage_ === "joborder" && refId ? [refId] : item.map(m => sourcePage_ === "roster" ? m.rosterCandidateId : (sourcePage_ === "joborder" ? m.jobOrderCandidateId : m.candidateId)),
            refType: sourcePage_ === "roster" ? "Roster" : (sourcePage_ === "joborder" ? "JobOrder" : "Candidate"),
            //messagesMasterId: selectedMessage?.lookupId,
            message: selectedMessage?.lookupValue,
            messageType: 'Notification',
            showYesNo: needYesNoResponse,
            showComment: needCommentResponse,
            inputPhoneNumberManually: inputPhoneNumberManually,
            phoneNumber: manualPhoneNumber,
        };
        // console.log('messageLog', messageLog);

        if (onCustomSave) {
            onCustomSave(selectedMessage?.lookupValue, setLoading, setFailedMessages, selectedMessage?.lookupId, messageLog, sourceObject);
            return;
        }

        setLoading(true);

        const data = JSON.stringify(messageLog);
        // console.log(data);
        const submit = api.post(`sendSMS/new-message`, data);

        submit.then((response) => {
            // console.log(response);
            if (response.data?.failed?.length > 0) {
                setFailedMessages(response.data.failed);
                setLoading(false);
                return;
            }
            setLoading(false);
            onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error({ error });
            if (error.response.status === 500) {
                setValidationAlert({
                    type: "Error",
                    message: "System Error"
                });
                return;
            }

            if (error?.response?.data?.title && error?.response?.data?.title.trim().toLowerCase() == 'one or more validation errors occurred.') {
                setValidationAlert({
                    type: "Error",
                    message: "Can't send invalid data, please check again."
                });
                return;
            }

            setValidationAlert({
                type: "Error",
                message: error.response.data
            });
        });
    }, [refId, selectedMessage, sourcePage, messageValidation, needYesNoResponse, needCommentResponse, onSaved, onCustomSave, inputPhoneNumberManually, manualPhoneNumber]);

    useEffect(() => {
        if (!needTalentResponse) {
            setNeedYesNoResponse(false);
            setNeedCommentResponse(false);
            return;
        }

        setNeedYesNoResponse(true);
        setNeedCommentResponse(true);

        if (!selectedMessage.lookupValue || !selectedMessage.lookupValue.includes("{FlowUrls.TalentResponseUrl}"))
            setSelectedMessage({ ...selectedMessage, lookupValue: `${selectedMessage.lookupValue ? `${selectedMessage.lookupValue} ` : ""}{FlowUrls.TalentResponseUrl}` })
    }, [needTalentResponse]);

    useEffect(() => {
        if (!needYesNoResponse && !needCommentResponse && needTalentResponse)
            setNeedTalentResponse(false);
    }, [needYesNoResponse, needCommentResponse]);

    return (
        <Modal
            isOpen={true}
            // className={sourcePage === 'roster' && (refId || props.items?.length) ? 'modal-xl' : 'modal-lg'}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    SMS Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className='px-0'>
                    {(loading) && <Loader />}
                    <Row>
                        {itemInEdit.some(s => !s.firstName) &&
                            <Col xs={12}>
                                <div className="alert alert-warning">You have selected item(s) with no candidate that might be ignored.</div>
                            </Col>
                        }

                        <Col xs={showProjectAttributes ? 8 : 12}>
                            {/* <Col xs={8}> */}
                            <Row>
                                {
                                    failedMessages?.length > 0 &&
                                    <Col className="font-size-message-alert-custom text-danger" xs={12}>
                                        <ul>
                                            {
                                                failedMessages.map((item, idx) => {
                                                    if (item.failCategory === "Empty Message")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span>Message cannot be empty.</span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "Invalid Attribute Usage")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span>{item.message}</span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "URL Response")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span><b>Add URL to Response</b> must be checked.</span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "Talent Response Parameter")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span><b>{"{FlowUrls.TalentResponseUrl}"}</b> must be provided.</span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "Message Unsent")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span>
                                                                    {
                                                                        `Message is failed to send to ${item.candidateName}.`
                                                                    }
                                                                </span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "Invalid Attribute")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span>
                                                                    {
                                                                        item.failAttribute
                                                                    }
                                                                </span>
                                                            </li>
                                                        );

                                                    if (item.failCategory === "Index Error")
                                                        return (
                                                            <li key={`fail_${idx}`}>
                                                                <span>
                                                                    SMS request is not able to be processed. Please contact your admin to check the log files.<br />
                                                                    Original error message: "Index was outside the bounds of the array."<br />
                                                                    Thank you.
                                                                </span>
                                                            </li>
                                                        );

                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>
                                                                {
                                                                    `${item.candidateName}: missing ${item.failCategory} - ${item.failAttribute}.`
                                                                }
                                                            </span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </Col>
                                }
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Selected Candidate(s)
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input placeholder={itemInEdit.filter(m => m.firstName).map(m => m.firstName + (m.surname ? ' ' + m.surname : '') + (m.mobilePhoneNumber ? ' (' + m.mobilePhoneNumber + ')' : ""))} readOnly />
                                    </FormGroup>
                                </Col>
                                {
                                    showTemplateOptions &&
                                    <>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <IndeterminateCheckbox
                                                    id="inputPhoneNumberManually"
                                                    type="checkbox"
                                                    labelClassName="job-order-control"
                                                    onChange={() => {
                                                        if (inputPhoneNumberManually)
                                                            setManualPhoneNumber(null);

                                                        setInputPhoneNumberManually(!inputPhoneNumberManually);
                                                    }}
                                                    checked={inputPhoneNumberManually}
                                                    customTitle="Input Phone Number manually"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label>
                                                    Phone Number
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    placeholder="Input phone number.."
                                                    onChange={(e) => setManualPhoneNumber(e.target.value)}
                                                    value={manualPhoneNumber ?? ""}
                                                    readOnly={!inputPhoneNumberManually}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label>
                                                    Message Template
                                                </Label>
                                                <DropdownBox
                                                    data={[...messageMasterList.filter(m => (!onCustomSave && m.lookupType && m.lookupType.toLowerCase() !== "availability" && m.lookupType.toLowerCase() !== "accommodation" && m.lookupType.toLowerCase() !== "das") || onCustomSave)] ?? []}
                                                    idField="lookupId"
                                                    valueField="lookupType"
                                                    selectedItem={selectedMessage}
                                                    onChange={(item) => {
                                                        // console.log(item);
                                                        setSelectedMessage(item);
                                                        // setTemplateFirstLoad(true);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                }
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Message Text
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            value={selectedMessage?.lookupValue ?? ""}
                                            onChange={(e) => {
                                                //console.log('onChange', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target})
                                                setSelectedMessage({ ...selectedMessage, lookupValue: e.target.value });
                                                setCursorPosition(e.target.selectionStart);
                                            }}
                                            onClick={(e) => {
                                                //console.log('onClick', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target})
                                                setCursorPosition(e.target.selectionStart)
                                            }}
                                            onKeyUp={(e) => {
                                                //console.log('onKeyDown', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target, code: e.nativeEvent.keyCode})
                                                if (e.nativeEvent.keyCode >= 37 && e.nativeEvent.keyCode <= 40) {
                                                    setCursorPosition(e.target.selectionStart)
                                                }
                                            }}
                                            rows={10}
                                        // disabled={!editorEnabled}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        {
                                            showTalentResponseOption ?
                                                <Col xs={12} md={4} lg={4} className="w-100">
                                                    <FormGroup>
                                                        <IndeterminateCheckbox
                                                            id={`talentResponse`}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setNeedTalentResponse(!needTalentResponse);
                                                            }}
                                                            checked={needTalentResponse}
                                                            customTitle="Add URL to Response"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                :
                                                null
                                        }
                                        {
                                            needTalentResponse &&
                                            <>
                                                <Col xs={12} md={4} lg={4}>
                                                    <FormGroup>
                                                        <IndeterminateCheckbox
                                                            id={`yesNoParam`}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setNeedYesNoResponse(!needYesNoResponse);
                                                            }}
                                                            checked={needYesNoResponse}
                                                            customTitle="Yes/No"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={4} lg={4}>
                                                    <FormGroup>
                                                        <IndeterminateCheckbox
                                                            id={`commentParam`}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setNeedCommentResponse(!needCommentResponse);
                                                            }}
                                                            checked={needCommentResponse}
                                                            customTitle="Comment"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        {
                            showProjectAttributes &&
                            <Col xs={4}>
                                <label>Variables</label>
                                <ListBox
                                    style={{
                                        width: "100%",
                                        height: "calc(100% - 29px)",
                                        maxHeight: 401,
                                        overflowY: "scroll",
                                        paddingBottom: "1rem",
                                    }}
                                    data={[...attributes.filter(d => !d.hidden)]}
                                    textField="name"
                                    valueField='value'
                                    onItemClick={(e) => {
                                        const value = selectedMessage?.lookupValue;
                                        const start = value?.substring(0, cursorPosition);
                                        const end = value?.substring(cursorPosition);
                                        // console.log('attribute click', {e, value, start, end, cursorPosition});
                                        setSelectedMessage({ ...selectedMessage, lookupValue: `${start ?? ""}${e.dataItem.name}${end ?? ""}` });
                                    }}
                                    item={ListBoxItem}
                                    onDragStart={handleDragStart}
                                />
                            </Col>
                        }
                    </Row>

                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    {buttonSendMessage == true && <><i className="fas fa-paper-plane mr-2"></i> Send SMS</>}
                    {buttonSendMessage == false && <><i className="fas fa-save mr-2"></i> Save SMS</>}

                </Button>
            </div>
        {
            validationAlert &&
            <SweetAlert
                title={validationAlert.title ?? "Error!"}
                error={validationAlert.type === "error" || true}
                warning={validationAlert.type === "warning"}
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => setValidationAlert(null)}
            >
                {
                    validationAlert.message
                }
            </SweetAlert>
        }
        </Modal>
    )
}

SMSEditor.propTypes = {
    onCustomSave: PropTypes.func,
    defaultMessage: PropTypes.object,
    sourcePage: PropTypes.string,
    refId: PropTypes.number,
    project: PropTypes.object,
    projectId: PropTypes.number,
    buttonSendMessage: PropTypes.bool,
    sourceObject: PropTypes.object,
    onSaved: PropTypes.func,
    defaultShowProjectAttribute: PropTypes.bool
}

SMSEditor.defaultProps = {
    buttonSendMessage: true,
    sourceObject: {},
    defaultShowProjectAttribute: true,
}